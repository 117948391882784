import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import { getParameterEndMatch } from '@/cms/NBossCMS.js';

import { getFlipHover } from '@/utils/GenericUtils';

export default function Grid(props) {
    const getPreloadItems = (count) => {
        let items = [];
        for (let i = 0; i < count; i++) items.push(<GridItem key={i} />);
        return items;
    }

    return (
        <div className={'flex justify-center ' + (props.className || '')}>
            <div className={'w-full max-w-tv-boxed mx-0 sm:mx-2 md:mx-4 my-1 sm:my-2 md:my-4 grid gap-1 sm:gap-2 md:gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4'}>
                {(props.data && props.data.value.parameterMap.itemList) ? props.data.value.parameterMap.itemList.value.contentList.map((item, index) => <GridItem key={index} data={item} />) : getPreloadItems(8)}
            </div>
        </div>
    );
}

function GridItem(props) {
    const [hover, setHover] = useState(null);
    const container = useRef();

    const animationDuration = 300;

    useEffect(() => {
        const to = setTimeout(() => { if (hover && hover.startsWith('out-')) setHover(null) }, animationDuration);
        return () => { clearTimeout(to) };
    }, [hover])

    const _data = {
        title: getParameterEndMatch(props.data, 'value', 'title'),
        titleTop: getParameterEndMatch(props.data, 'value', 'title-top'),
        titleBottom: getParameterEndMatch(props.data, 'value', 'title-bottom'),
        title: getParameterEndMatch(props.data, 'value', 'title'),
        icon: getParameterEndMatch(props.data, 'value', 'icon'),
        background: getParameterEndMatch(props.data, 'value', 'background'),
        to: getParameterEndMatch(props.data, 'value', 'url'),
        url: getParameterEndMatch(props.data, 'value', 'outUrl'),
        highlight: getParameterEndMatch(props.data, 'value', 'highlight') ? true : false
    };

    const _setHover = (e, show) => { if (props.data) setHover(getFlipHover(container, e, show)); }

    if (_data.to)
        return (<Link ref={container} to={_data.to} className={'relative overflow-hidden'} onMouseEnter={e => _setHover(e, true)} onMouseLeave={e => _setHover(e, false)}><GridItemInside animationDuration={animationDuration} hover={hover} data={_data} /></Link>);
    else
        return (<a onMouseEnter={e => _setHover(e, true)} onMouseLeave={e => _setHover(e, false)} ref={container} className={'relative overflow-hidden'} href={_data.url}><GridItemInside animationDuration={animationDuration} hover={hover} data={_data} /></a>);
}

function GridItemInside(props) {
    const getLabelArray = () => {
        if (props.data && props.data.title) {
            let arr = [], parts = props.data.title.split(' '), last = parts.pop();
            arr.push(parts.join(' '));
            arr.push(last);
            return arr;
        }
        return null;
    }

    return (
        <>
            <svg className='invisible' viewBox='0 0 1 1' />

            <div className={'absolute -inset-0 w-full h-full items-center'} style={{ perspective: 1500 }}>

                <div className={'absolute w-full h-full' + (props.data.highlight ? ' bg-tv-red dark:bg-tv-purple' : ' bg-tv-gray-light dark:bg-tv-yellow')} />

                {props.data.background ? <motion.div
                    className='absolute w-full h-full bg-center bg-no-repeat bg-cover filter grayscale opacity-20'
                    style={{ backgroundImage: 'url(' + props.data.background.downloadUrl + ')' }}
                    transition={{ ease: 'easeOut', duration: props.animationDuration / 1000 }}
                    initial={'normal'}
                    animate={(props.hover && props.hover.startsWith('in-')) ? 'hover' : 'normal'}
                    variants={{ 'hover': { scale: 1.1 }, 'normal': { scale: 1 } }} /> : null}


                <div className={'absolute w-full h-full transform-gpu'
                    + ' ' + (props.hover ? props.hover : 'opacity-0')
                    + ' ' + (props.data.highlight ? (props.data.background ? 'bg-tv-purple dark:bg-tv-gray-darker' : 'bg-tv-purple dark:bg-tv-gray-dark') : (props.data.background ? 'bg-tv-yellow dark:bg-white' : 'bg-tv-yellow dark:bg-white'))}>

                    {props.data.background ? <div className='absolute w-full h-full bg-center bg-no-repeat bg-cover filter grayscale opacity-20' style={{ backgroundImage: 'url(' + props.data.background.downloadUrl + ')' }} /> : null}

                </div>

                <div className='absolute w-full h-full p-4 sm:p-6 flex items-center'>

                    {props.data.icon ? <img className={'absolute w-11 h-8 sm:w-12 sm:h-9 bottom-4 right-4'} alt='icon' src={props.data.icon.downloadUrl} /> : null}

                    <div className={'w-full uppercase tracking-widest ' + (props.data.highlight ? ' text-white' : ' dark:text-black')}>

                        {props.data.titleTop && <div className='text-xs dark:text-sm sm:text-sm sm:dark:text-lg font-thin dark:font-normal'>{props.data.titleTop}</div>}
                        {props.data.titleBottom && <div className='text-sm dark:text-md sm:text-lg sm:dark:text-2xl font-semibold dark:font-bold'>{props.data.titleBottom}</div>}

                        {/* {getLabelArray() ? <div>
                            <div className='text-xs dark:text-sm sm:text-sm sm:dark:text-lg font-thin dark:font-normal'>{getLabelArray()[0]}</div>
                            <div className='text-sm dark:text-md sm:text-lg sm:dark:text-2xl font-semibold dark:font-bold'>{getLabelArray()[1]}</div>
                        </div> : null} */}

                    </div>

                </div>

            </div>
        </>
    );
}