import React, { useState, useEffect } from 'react';
import { EventRegister } from '@/utils/EventRegister.js';

import DynamicIcon from '@/elements/DynamicIcon.js';

import { getTranslation } from '@/utils/DictionaryUtils.js';
import { getUser } from '@/utils/UserUtils.js';

export default function AuthIndicator(props) {
    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        setLoggedIn(getUser() ? true : false)
        let userChangedEvent = EventRegister.addEventListener('userChanged', (data) => { setLoggedIn(data ? true : false); });
        return () => EventRegister.removeEventListener(userChangedEvent)
    }, []);

    return (
        <button className={'flex flex-row items-center ' + (props.className || '')} onClick={() => EventRegister.emit('userAuthNeeded', null)}>

            {props.icon ? <DynamicIcon className='mr-3 mb-0.5' iconName='FaUser' /> : null}
            <span className='leading-nonee'>{loggedIn ? getTranslation('logout') : getTranslation('login')}</span>

        </button>
    )
}