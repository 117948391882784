import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

import OrbButton from '@/elements/OrbButton.js';
import MultiLevelMenu from '@/elements/MultiLevelMenu.js';
import AuthIndicator from '@/elements/AuthIndicator.js';

import { getWebsite } from '@/utils/WebsiteUtils.js';
import { getValueFromParameterMap } from '@/cms/NBossCMS.js';

export default function MenuSlider(props) {
    let location = useLocation();

    useEffect(() => {
        // if (props.onSliderClose())
        // props.onSliderClose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    return (
        <motion.div
            className={'fixed w-full h-full z-50 ' + (props.className || '')}
            initial={props.open ? 'visible' : 'hidden'}
            animate={props.open ? 'visible' : 'hidden'}
            variants={{ visible: { opacity: 1, display: 'flex' }, hidden: { opacity: 0.9999, transitionEnd: { display: 'none' } } }}>

            <motion.div
                className='absolute w-full h-full bg-white'
                initial={props.open ? 'visible' : 'hidden'}
                animate={props.open ? 'visible' : 'hidden'}
                variants={{ visible: { opacity: 0.5 }, hidden: { opacity: 0 } }}
                onClick={() => props.onSliderClose()} />

            <div className='absolute right-0 h-full w-3/4 max-w-sm filter'>

                <motion.div
                    className='bg-tv-red dark:bg-tv-gray-darkest h-full flex flex-col gap-4 py-6'
                    initial={props.open ? 'visible' : 'hidden'}
                    animate={props.open ? 'visible' : 'hidden'}
                    style={{ originX: 1, originY: 0.5, transformPerspective: 1250 }}
                    transition={{ duration: 0.35 }}
                    variants={{ visible: { rotateY: 0, display: 'flex' }, hidden: { rotateY: -90, transitionEnd: { display: 'none' } } }}>

                    <div className='relative w-full flex px-6' >

                        <Link to='/' className='h-36 flex-1'>
                            <img className='h-full' alt='logo' src='/assets/images/logo/shield-text-btm-outline-white.svg' />
                        </Link>

                        <OrbButton
                            className='w-9 h-9'
                            data={{ iconName: 'FaTimes', iconClassName: 'text-lg text-white', backgroundClassName: 'bg-white bg-opacity-30' }}
                            onClick={() => props.onSliderClose()} />
                    </div>

                    <AuthIndicator icon className='mx-6 px-4 py-3 text-center rounded border-white border border-opacity-50 text-white font-light uppercase tracking-wide text-sm'/>

                    <MultiLevelMenu
                        className='w-full flex-1 overflow-y-auto overflow-x-hidden px-6'
                        data={getValueFromParameterMap(getWebsite().globalContent, 'menu')}
                        baseLevel={0}
                        firstLevelKey='itemList'
                        activeClassName='font-semibold'
                        baseClassName='text-white font-thin tracking-wider'
                        mainClassName='text-sm uppercase'
                        subClassName='text-xs'
                        orbClassName='bg-white'
                        iconClassName='text-tv-red dark:text-white'
                        onChildExistClick={() => console.log("Has child.")}
                        onNoChildExistClick={() => props.onSliderClose()} />

                    <div className='w-full flex-col items-center justify-center text-center text-white text-xxs font-thin leading-4 px-6'>
                        <span>2022 © Terézváros Önkormányzata<br />Minden jog fenntartva.</span>
                    </div>

                </motion.div>

            </div>

        </motion.div >
    );
}