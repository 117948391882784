import { useRef, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import useResizeObserver from 'use-resize-observer';
import GoogleMapReact from 'google-map-react';
import Supercluster from 'supercluster';

import Tv2030Modal from '@/components/tv2030/Tv2030Modal.js';
import DynamicIcon from '@/elements/DynamicIcon.js';

// import MapControl from '@/services/MapControl.js';

import mapConfig from '@/config/mapConfig.js'

import { getValueFromParameterMap } from '@/cms/NBossCMS.js';
import { getQueryVariable } from '@/utils/GenericUtils.js';
import QueryControl from '@/services/QueryControl.js';

export const queryParam = 'fejlesztes';

export default function Tv2030Map(props) {
    const [h, setH] = useState(0);
    const [y, setY] = useState(0);

    const [itemList, setItemList] = useState(null);
    const [supercluster, setSupercluster] = useState(null);
    const [markers, setMarkers] = useState(null);

    const [itemKey, setItemKey] = useState(null);
    const [mapType, setMapType] = useState('ROADMAP');

    const [map, setMap] = useState(null);
    const [maps, setMaps] = useState(null);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(15);
    const [polyItems, setPolyItems] = useState(null);

    const [filter, setFilter] = useState({ readiness: ['UNDER_PREPARATION', 'UNDER_IMPLEMENTATION', 'COMPLETED_DEVELOPMENT'/*, 'LONG_TERM_DEVELOPMENT'*/], type: ['PUBLIC_SPACE'] });

    const container = useRef();
    const minHeight = 640;

    let location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (itemList !== null) {
            let id = getQueryVariable(location.search, queryParam) || '';
            console.log('Id: ' + id + ' -> Key: ' + getKeyById(id));
            setItemKey(getKeyById(id));
        }
    }, [location, itemList]);

    // useEffect(() => {
    //     if (location.pathname.startsWith('/terezvaros-2030/') && h)
    //         scrollTo(0, h)
    // }, [location, h]);

    const getKeyById = (id) => {
        if (itemList) {
            for (let i = 0; i < itemList.length; i++)
                // console.log(itemList[i]);
                if (itemList[i].content.id == id)
                    return itemList[i].content.key;
        }
        return null;
    };

    const _setItemId = (id) => {
        let path = null;
        if (id && id !== '') path = location.pathname + '?' + queryParam + '=' + id;
        else path = location.pathname;
        history.push(path);
    }

    const handleApiLoaded = (map, maps) => {
        setMap(map);
        setMaps(maps);
    }

    useResizeObserver({
        ref: container,
        onResize: ({ width, height }) => {
            if (container && container.current)
                resize();
        }
    });

    const resize = () => {
        setH(window.innerHeight - container.current.getBoundingClientRect().y - window.scrollY);
        setY(container.current.getBoundingClientRect().y + window.scrollY);
    };

    // useEffect(() => {
    //     MapControl.getProjectList().then(result => { setItemList(result) });
    // }, []);

    useEffect(() => {
        if (props.initialData) setItemList(props.initialData);
    }, [props.initialData]);

    useEffect(() => {
        resize(); // MI A KOLBÁSZÉRT KELL EZ???

        if (supercluster && bounds && zoom)
            setMarkers(supercluster.getClusters(bounds, zoom));
    }, [bounds, zoom, supercluster]);

    useEffect(() => {
        if (itemList) {
            let list = [];
            for (let i = 0; i < itemList.length; i++) {
                const item = itemList[i];

                const readiness = getValueFromParameterMap(item.content, 'readiness') && getValueFromParameterMap(getValueFromParameterMap(item.content, 'readiness'), 'key');
                const type = getValueFromParameterMap(item.content, 'type') && getValueFromParameterMap(getValueFromParameterMap(item.content, 'type'), 'key');

                if (item.mapData) {
                    for (let j = 0; j < item.mapData.length; j++) {
                        const mapItem = item.mapData[j];

                        if ((type === 'PUBLIC_SPACE' && filter.readiness.indexOf(readiness) > -1 && filter.type.indexOf(type) > -1) || (type !== 'PUBLIC_SPACE' && filter.type.indexOf(type) > -1)) {

                            if (mapItem.type === 'MARKER' || mapItem.type === 'UNDER_PLANNING_MARKER') {
                                list.push({
                                    type: 'Feature',
                                    properties: { cluster: false },
                                    geometry: { type: 'Point', coordinates: [parseFloat(mapItem.position.lng), parseFloat(mapItem.position.lat)] },
                                    data: item,
                                    pointerData: mapItem
                                });
                            }

                        }

                    }
                }
            }
            const supercluster = new Supercluster({ radius: 90, maxZoom: 20 });
            supercluster.load(list);

            setSupercluster(supercluster);
        }
    }, [itemList, filter]);

    useEffect(() => {
        removePolyItems(polyItems);
        let items = drawPolyItems(map, maps, itemList, false, zoom, filter);
        setPolyItems(items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, maps, itemList, zoom, filter]);

    // const handleChange = (params) => {
    //     let data = { readiness: filter.readiness, type: filter.type };
    //     if (params.readiness) {
    //         data.type = [];
    //         if (data.readiness.indexOf(params.readiness) > -1) data.readiness.splice(data.readiness.indexOf(params.readiness), 1); else data.readiness.push(params.readiness);
    //     } else if (params.type) {
    //         data.readiness = [];
    //         if (data.type.indexOf(params.type) > -1) data.type.splice(data.type.indexOf(params.type), 1); else data.type.push(params.type);
    //     }
    //     setFilter(data);
    // }

    const handleChange = (params) => {
        // console.log(params);
        let data = { readiness: filter.readiness, type: filter.type };
        if (params.readiness) {

            if (data.readiness.indexOf(params.readiness) > -1)
                data.readiness.splice(data.readiness.indexOf(params.readiness), 1);
            else
                data.readiness.push(params.readiness);

            if (data.readiness.length > 0) {
                if (data.type.indexOf('PUBLIC_SPACE') < 0)
                    data.type.push('PUBLIC_SPACE');
            } else {
                if (data.type.indexOf('PUBLIC_SPACE') > -1)
                    data.type.splice(data.type.indexOf('PUBLIC_SPACE'), 1);
            }

        } else if (params.type) {

            if (data.type.indexOf(params.type) > -1)
                data.type.splice(data.type.indexOf(params.type), 1);
            else
                data.type.push(params.type);

        }

        setFilter(data);
    }

    return (
        <div className={'relative w-full bg-tv-gray-light shadow-xl overflow-hidden ' + (props.className || '')} style={{ height: h ? (Math.max(h, minHeight) + 'px') : '0px' }} ref={container}>

            <GoogleMapReact
                key='map'
                bootstrapURLKeys={{
                    key: 'AIzaSyD1luTKqk_RQjga3FZ2vTeFJhms3nDrjSg',
                    language: 'hu-HU',
                    region: 'hu',
                }}
                options={map => ({
                    disableDefaultUI: true,
                    styles: mapConfig.styles,
                    mapTypeId: map.MapTypeId[mapType],
                })}
                defaultCenter={{
                    lat: mapConfig.defaultCoordinates.lat,
                    lng: mapConfig.defaultCoordinates.lng,
                }}
                onChange={({ zoom, bounds }) => {
                    setZoom(zoom);
                    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
                }}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                defaultZoom={15}
                disableDefaultUI={true}>

                {markers && markers.map((cluster, index) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                    if (isCluster) { return (<Cluster key={index} lat={latitude} lng={longitude} pointCount={pointCount} onClick={() => { const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20); map.setZoom(expansionZoom); map.panTo({ lat: latitude, lng: longitude }); }} />); }

                    return (<Marker key={index} lat={latitude} lng={longitude} data={cluster.data} pointerData={cluster.pointerData} filter={filter} onClick={() => /*_setItemKey(cluster.data.content.key)*/_setItemId(cluster.data.content.id)} />);

                })}

            </GoogleMapReact>

            <div className='absolute top-0 left-0 w-full h-full pointer-events-none overflow-hidden px-4 py-4'>

                <ActionBar
                    onChange={(params) => handleChange(params)}
                    onChangeMapType={type => setMapType(type)}
                    onItemKey={(itemKey, itemId) => /*_setItemKey(itemKey)*/_setItemId(itemId)}
                    onScrollToList={() => { window.scrollTo({ top: h + y - 24, behavior: 'smooth' }) }}
                    itemList={itemList}
                    filter={filter}
                />

            </div>

            {itemKey && <Tv2030Modal filter={filter} itemKey={itemKey} onClose={() => /*_setItemKey(false)*/_setItemId(null)} />}

        </div>
    )
}

export const removePolyItems = (polyItemList) => {
    if (polyItemList && polyItemList.polygonList)
        for (let i = 0; i < polyItemList.polygonList.length; i++)
            polyItemList.polygonList[i].setMap(null);
    if (polyItemList && polyItemList.polylineList)
        for (let i = 0; i < polyItemList.polylineList.length; i++)
            polyItemList.polylineList[i].setMap(null);
};

export const drawPolyItems = (map, maps, itemList, fixStrokeWeight, zoom, filter) => {
    const strokeOpacity = 0.65;
    const fillOpacity = 0.25;
    const minStrokeWeight = 4;
    const maxStrokeWeight = 20;

    let strokeWeight = 5;

    if (!fixStrokeWeight) {

        let divider = 3;
        if (zoom >= 16) divider = 2;
        if (zoom >= 18) divider = 1.05;
        if (zoom >= 20) divider = 0.4;

        strokeWeight = Math.ceil(zoom / divider);
        if (strokeWeight < minStrokeWeight) strokeWeight = minStrokeWeight;
        else if (strokeWeight > maxStrokeWeight) strokeWeight = maxStrokeWeight;

    } else {

        strokeWeight = fixStrokeWeight;

    }

    let polygonList = [];
    let polylineList = [];

    const _getMapItem = (r, t) => { return getMapItem(filter, r, t); };

    if (map && maps) {
        // if (!disableDiscrit) {
        const outerbounds = [new maps.LatLng(0, -90), new maps.LatLng(0, 90), new maps.LatLng(90, -90), new maps.LatLng(90, 90),];

        const discrit = new maps.Polygon({
            paths: [mapConfig.discritPolygonPath, outerbounds],
            strokeColor: '#82c195',
            fillColor: '#000000',
            fillOpacity: 0.2,
            strokeOpacity: strokeOpacity,
            strokeWeight: strokeWeight,
            clickable: false
        });
        discrit.setMap(map);
        polylineList.push(discrit);
        // }

        if (itemList) {
            for (let i = 0; i < itemList.length; i++) {
                const item = itemList[i];

                const readiness = getValueFromParameterMap(item.content, 'readiness') && getValueFromParameterMap(getValueFromParameterMap(item.content, 'readiness'), 'key');
                const type = getValueFromParameterMap(item.content, 'type') && getValueFromParameterMap(getValueFromParameterMap(item.content, 'type'), 'key');

                if (item.mapData) {
                    for (let j = 0; j < item.mapData.length; j++) {
                        const element = item.mapData[j];

                        if ((type === 'PUBLIC_SPACE' && filter.readiness.indexOf(readiness) > -1 && filter.type.indexOf(type) > -1) || (type !== 'PUBLIC_SPACE' && filter.type.indexOf(type) > -1)) {

                            // const strokeColor=

                            if (element.type === 'POLYLINE') {

                                const polyline = new maps.Polyline({ path: element.path, strokeColor: _getMapItem(readiness, type).stroke, strokeOpacity: strokeOpacity, strokeWeight: strokeWeight, fillOpacity: 0, clickable: false });
                                polyline.setMap(map);
                                polylineList.push(polyline);

                            } else if (element.type === 'POLYGON') {

                                const polygon = new maps.Polygon({ paths: element.path, fillColor: _getMapItem(readiness, type).stroke, strokeColor: _getMapItem(readiness, type).stroke, strokeOpacity: strokeOpacity, strokeWeight: strokeWeight, fillOpacity: fillOpacity, clickable: false });
                                polygon.setMap(map);
                                polygonList.push(polygon);

                            }

                        }

                    }
                }

            }
        }

        return { polygonList: polygonList, polylineList: polylineList };

    }
}
const readinessMap = {
    'UNDER_PREPARATION': {
        'icon': {
            'transparent': 'red-transparent.svg',
            'fill': 'red-fill.svg'
        },
        'planningIcon': {
            'transparent': 'red-transparent.svg',
            'fill': 'red-fill.svg'
        },
        'background': 'bg-tv-red',
        'stroke': '#f04e3e'
    },
    'COMPLETED_DEVELOPMENT': {
        'icon': {
            'transparent': 'purple-transparent.svg',
            'fill': 'purple-fill.svg'
        },
        'planningIcon': {
            'transparent': 'purple-transparent.svg',
            'fill': 'purple-fill.svg'
        },
        'background': 'bg-tv-purple',
        'stroke': '#6f3c6e'
    },
    'UNDER_IMPLEMENTATION': {
        'icon': {
            'transparent': 'yellow-transparent.svg',
            'fill': 'yellow-fill.svg'
        },
        'planningIcon': {
            'transparent': 'yellow-transparent.svg',
            'fill': 'yellow-fill.svg'
        },
        'background': 'bg-tv-yellow',
        'stroke': '#ffd074'
    },
    'LONG_TERM_DEVELOPMENT': {
        'icon': {
            'transparent': 'cyan-transparent.svg',
            'fill': 'cyan-fill.svg'
        },
        'planningIcon': {
            'transparent': 'cyan-transparent.svg',
            'fill': 'cyan-fill.svg'
        },
        'background': 'bg-tv-cyan',
        'stroke': '#1cb4bb'
    },
};

const typeMap = {
    'PUBLIC_SPACE': {
        'icon': {
            'transparent': 'type/mobi.svg',
            'fill': 'type/mobi.svg',
        },
        'planningIcon': {
            'transparent': 'type/mobi.svg',
            'fill': 'type/mobi.svg',
        },
        'background': 'bg-black'
    },
    'MOBI': {
        'icon': {
            'transparent': 'type/mobi.svg',
            'fill': 'type/mobi.svg',
        },
        'planningIcon': {
            'transparent': 'type/mobi-up.svg',
            'fill': 'type/mobi-up.svg',
        },
        'background': 'bg-tv-green-vibrant'
    },
    'BUBI': {
        'icon': {
            'transparent': 'type/bubi.svg',
            'fill': 'type/bubi.svg',
        },
        'planningIcon': {
            'transparent': 'type/bubi.svg',
            'fill': 'type/bubi.svg',
        },
        'background': 'bg-tv-green-vibrant',
        'stroke': '#98c421'
    },
    'BIKE_SUPPORT': {
        'icon': {
            'transparent': 'type/bike-support.svg',
            'fill': 'type/bike-support.svg',
        },
        'planningIcon': {
            'transparent': 'type/bike-support.svg',
            'fill': 'type/bike-support.svg',
        },
        'background': 'bg-tv-green-vibrant',
        'stroke': '#98c421'
    },
    'BIKE_TRAIL': {
        'icon': {
            'transparent': 'type/bike-trail.svg',
            'fill': 'type/bike-trail.svg',
        },
        'planningIcon': {
            'transparent': 'type/bike-trail.svg',
            'fill': 'type/bike-trail.svg',
        },
        'background': 'bg-tv-green-vibrant',
        'stroke': '#f90024'
    },
    'TEMPO_30': {
        'icon': {
            'transparent': 'type/tempo30.svg',
            'fill': 'type/tempo30.svg',
        },
        'planningIcon': {
            'transparent': 'type/tempo30.svg',
            'fill': 'type/tempo30.svg',
        },
        'background': 'bg-tv-red',
        'stroke': '#f04e3e'
    },
    'NODE_HIGHLIGHT': {
        'icon': {
            'transparent': 'type/tempo30.svg',
            'fill': 'type/tempo30.svg',
        },
        'planningIcon': {
            'transparent': 'type/tempo30.svg',
            'fill': 'type/tempo30.svg',
        },
        'background': 'bg-tv-red',
        'stroke': '#f04e3e'
    },
    'RESIDENTIAL_PARKING': {
        'icon': {
            'transparent': 'type/residental-parking.svg',
            'fill': 'type/residental-parking.svg',
        },
        'planningIcon': {
            'transparent': 'type/residental-parking.svg',
            'fill': 'type/residental-parking.svg',
        },
        'background': 'bg-tv-red',
        'stroke': '#a0a0a0'
    },
    'PARKING_BUILDING': {
        'icon': {
            'transparent': 'type/parking-building.svg',
            'fill': 'type/parking-building.svg',
        },
        'planningIcon': {
            'transparent': 'type/parking-building.svg',
            'fill': 'type/parking-building.svg',
        },
        'background': 'bg-tv-purple-dark',
        'stroke': '#532c52'
    },
    'FACADE_RENOVATION': {
        'icon': {
            'transparent': 'type/facade.svg',
            'fill': 'type/facade.svg',
        },
        'planningIcon': {
            'transparent': 'type/facade.svg',
            'fill': 'type/facade.svg',
        },
        'background': 'bg-tv-yellow',
        'stroke': '#ffd074'
    },
    'CAMERA': {
        'icon': {
            'transparent': 'type/camera.svg',
            'fill': 'type/camera.svg',
        },
        'planningIcon': {
            'transparent': 'type/camera.svg',
            'fill': 'type/camera.svg',
        },
        'background': 'bg-tv-gray-darkest',
        'stroke': '#141414'
    },
    'PUBLIC_INSTITUTION': {
        'icon': {
            'transparent': 'type/public-institution.svg',
            'fill': 'type/public-institution.svg',
        },
        'planningIcon': {
            'transparent': 'type/public-institution.svg',
            'fill': 'type/public-institution.svg',
        },
        'background': 'bg-tv-purple',
        'stroke': '#a559a4'
    },
};

// const getMapItem = (filter, readiness, type) => {
//     if (filter) {
//         if (filter.readiness && filter.readiness.length > 0)
//             return readinessMap[readiness];
//         if (filter.type && filter.type.length > 0)
//             return typeMap[type];
//     }
//     return null;
// };

// const getMapItem = (filter, readiness, type) => {
//     if (filter) {
//         if (filter.readiness && filter.readiness.length > 0)
//             return readinessMap[readiness] ? readinessMap[readiness] : readinessMap['COMPLETED_DEVELOPMENT'];

//         if (filter.type && filter.type.length > 0)
//             return typeMap[type] ? typeMap[type] : readinessMap['COMPLETED_DEVELOPMENT'];
//     }
//     return readinessMap['COMPLETED_DEVELOPMENT'];
// };

const getMapItem = (filter, readiness, type) => {
    if (filter) {
        if (filter.readiness && filter.readiness.length > 0 && type === 'PUBLIC_SPACE')
            return readinessMap[readiness] ? readinessMap[readiness] : readinessMap['COMPLETED_DEVELOPMENT'];

        if (filter.type && filter.type.length > 0 && type !== 'PUBLIC_SPACE')
            return typeMap[type] ? typeMap[type] : readinessMap['COMPLETED_DEVELOPMENT'];
    }
    return readinessMap['COMPLETED_DEVELOPMENT'];
};

export function Marker(props) {
    const [open, setOpen] = useState(false);

    const _data = {
        title: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'title'),
        subTitle: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'sub-title'),
        type: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'type'),
        readiness: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'readiness'),
        coverImage: (props.data && props.data.content) && (getValueFromParameterMap(props.data.content, 'cover-image') && getValueFromParameterMap(props.data['content'], 'cover-image').downloadUrl),
    }

    const _getMapItem = () => { return getMapItem(props.filter, getValueFromParameterMap(_data.readiness, 'key'), getValueFromParameterMap(_data.type, 'key')); };

    return (
        <div className={'relative w-0 h-0 ' + (open ? 'z-50 ' : 'z-0 ') + (props.passive ? '' : 'cursor-pointer')} onMouseEnter={() => !props.passive && setOpen(true)} onMouseLeave={() => !props.passive && setOpen(false)} onClick={(e) => { if (!props.passive) props.onClick(e) }}>

            {props.data && <motion.div
                className='absolute flex bottom-7 -left-10 w-64 rounded-md overflow-hidden bg-tv-gray-darker'
                style={{ backfaceVisibility: 'hidden', originX: 0.15, originY: 1, transformPerspective: 1000 }}
                initial={'hidden'}
                animate={open ? 'visible' : 'hidden'}
                transition={{ duration: 0.25 }}
                variants={{ 'visible': { opacity: 1, rotateX: 0, translateY: 0 }, 'hidden': { opacity: 0, rotateX: 90, translateY: -10 } }}>

                <div className='absolute w-full h-full bg-no-repeat bg-center bg-cover filter grayscale opacity-10 ' style={{ backgroundImage: 'url(' + _data.coverImage + ')' }} />

                <div className='pl-6 pr-4 pt-14 pb-12 text-white z-50'>
                    <div className='font-light tracking-wide uppercase'>{getValueFromParameterMap(_data.type, 'title')}</div>
                    {_data.title && <div className={'font-bold leading-tight ' + (_data.title.length > 16 ? (_data.title.length > 28 ? 'text-lg my-1' : 'text-xl my-1') : 'text-2xl')}>{_data.title}</div>}
                    {_data.subTitle && <div className='font-light text-xs mt-1'>{_data.subTitle}</div>}
                </div>

                <div className={'absolute bottom-0 px-1 pt-2 pb-4 rounded-t-lg ' + _getMapItem().background} style={{ width: '35px', left: '22px' }}>
                    <div className='w-full text-center text-xxs font-semibold text-white'>2030</div>
                </div>

                <div className={'absolute top-0 w-8 px-2 py-2 rounded-b-lg ' + _getMapItem().background} style={{ width: '35px', left: '22px' }}>
                    <img className='w-full' alt='logo' src='/assets/images/logo/shield-fill-white.svg' />
                </div>

            </motion.div>}

            <div className='absolute -left-5 -bottom-1 w-10' alt='marker' >
                <img className={'absolute w-full -bottom-1 transition ' + (open ? '' : '')} alt='marker' src={'/assets/images/tv-2030-map/marker/' + _getMapItem()[props.pointerData.type === 'UNDER_PLANNING_MARKER' ? 'planningIcon' : 'icon'].transparent} />
                <img className={'absolute w-full -bottom-1 transition ' + (open ? 'opacity-100' : 'opacity-0')} alt='marker' src={'/assets/images/tv-2030-map/marker/' + _getMapItem()[props.pointerData.type === 'UNDER_PLANNING_MARKER' ? 'planningIcon' : 'icon'].fill} />
            </div>

        </div>
    );
}

export function Cluster(props) {
    return (
        <div className='w-8 h-8 bg-tv-green rounded-full ring-4 ring-offset-2 ring-opacity-25 ring-tv-green flex items-center justify-center shadow-lg cursor-pointer' onClick={props.onClick}>
            <div className='text-white font-bold'>{props.pointCount}</div>
        </div>
    );
}

function ActionBar(props) {
    const [open, setOpen] = useState(true);

    return (
        <>
            <motion.button
                className='absolute top-0 left-0 m-4 bg-tv-gray-light bg-opacity-75 rounded-lg shadow-tv-2030-action-bar p-2 pointer-events-auto group'
                onClick={() => setOpen(!open)}
                initial={'hidden'}
                animate={open ? 'hidden' : 'visible'}
                variants={{ 'visible': { opacity: 1, translateX: 0 }, 'hidden': { opacity: 1, translateX: '-133%' } }}>

                <div className='relative w-14 h-14 rounded-md flex items-center justify-center overflow-hidden'>
                    <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-b from-tv-red to-tv-red-dark' />
                    <div className='absolute top-0 left-0 w-full h-full bg-gradient-to-b from-tv-purple to-tv-purple-dark opacity-0 group-hover:opacity-100 transition' />
                    <DynamicIcon className='z-10 text-2xl text-white' iconName='FaFilter' />
                </div>

            </motion.button>

            <motion.div
                className='w-full sm:w-80 lg:w-88 flex flex-col max-h-full bg-tv-gray-light bg-opacity-75 backdrop-filter backdrop-blur-sm shadow-tv-2030-action-bar rounded-lg pointer-events-auto py-4 pr-0'
                initial={'visible'}
                animate={open ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: 1, translateY: 0 }, 'hidden': { opacity: 1, translateY: '-133%' } }}>

                <div className='flex-1 flex flex-col gap-4 rounded-lg overflow-y-scroll line-scrollbar pl-4 pr-2'>

                    <div className='flex items-stretch justify-between'>

                        <div className='flex gap-4 items-center'>
                            <div className='w-14 h-14 rounded-md bg-gradient-to-b from-tv-red to-tv-red-dark flex items-center justify-center' >
                                <img className='w-9 h-9' alt='logo' src='/assets/images/logo/shield-fill-white.svg' />
                            </div>

                            <div className='flex flex-col gap-1'>
                                <div className='uppercase font-bold text-xl sm:text-lg lg:text-xl border-b border-tv-red'>Terézváros 2030</div>
                                <div className='text-sm sm:text-xs lg:text-sm'>Közterület-megújítási program</div>
                            </div>
                        </div>

                        <button className='mt-px w-7 min-h-full flex items-center justify-center rounded-md text-tv-gray-darker ring-1 ring-tv-gray bg-white bg-opacity-0 hover:bg-opacity-50' onClick={() => setOpen(!open)}>
                            <DynamicIcon className='' iconName='FaChevronUp' />
                        </button>

                    </div>

                    <SearchBar itemList={props.itemList} onItemKey={props.onItemKey} />

                    <div className='flex justify-center gap-8 py-4'>

                        <MapButton onClick={() => props.onChangeMapType('ROADMAP')}><img className='w-full h-full' alt='logo' src='/assets/images/tv-2030-map/map-type/map-1.png' /></MapButton>

                        <MapButton onClick={() => props.onChangeMapType('HYBRID')}><img className='w-full h-full' alt='logo' src='/assets/images/tv-2030-map/map-type/map-2.png' /></MapButton>

                        <MapButton onClick={() => props.onScrollToList()}><DynamicIcon className='text-2xl text-tv-gray-dark group-hover:text-tv-red transition' iconName='FaListUl' /></MapButton>

                    </div>

                    <div className='flex flex-col gap-3'>

                        <Dropdown title='Közterület fejlesztések' icon='FaMapSigns'>
                            <DropdownItem title='előkészítés alatt' array='readiness' enum='UNDER_PREPARATION' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-2.5 h-2.5 bg-tv-red rounded-full' />} />
                            <DropdownItem title='megvalósítás alatt' array='readiness' enum='UNDER_IMPLEMENTATION' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-2.5 h-2.5 bg-tv-yellow rounded-full' />} />
                            <DropdownItem title='lezárult fejlesztés' array='readiness' enum='COMPLETED_DEVELOPMENT' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-2.5 h-2.5 bg-tv-purple rounded-full' />} />
                            <DropdownItem title='távlati fejlesztés' array='readiness' enum='LONG_TERM_DEVELOPMENT' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-2.5 h-2.5 bg-tv-cyan rounded-full' />} />
                        </Dropdown>

                        <Dropdown title='Közlekedési fejlesztések' icon='FaBicycle'>
                            <DropdownItem title='Mobi-pontok' array='type' enum='MOBI' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/mobi.svg)' }} />} />
                            <DropdownItem title='MOL Bubi gyűjtőállomások' array='type' enum='BUBI' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/bubi.svg)' }} />} />
                            <DropdownItem title='Kerékpáros nyomvonal fejlesztések' array='type' enum='BIKE_TRAIL' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/bike-trail.svg)' }} />} />
                            <DropdownItem title='Zóna30' array='type' enum='TEMPO_30' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/tempo30.svg)' }} />} />
                            <DropdownItem title='Lakossági parkolás' array='type' enum='RESIDENTIAL_PARKING' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/residental-parking.svg)' }} />} />
                            <DropdownItem title='Parkolóházak' array='type' enum='PARKING_BUILDING' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/parking-building.svg)' }} />} />
                        </Dropdown>

                        <Dropdown title='További fejlesztések' icon='FaSeedling'>
                            <DropdownItem title='Közintézmény fejlesztések' array='type' enum='PUBLIC_INSTITUTION' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/public-institution.svg)' }} />} />
                            <DropdownItem title='Homlokzatfelújítási program' array='type' enum='FACADE_RENOVATION' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/facade.svg)' }} />} />
                            <DropdownItem title='Térfigyelő kamerarendszer' array='type' enum='CAMERA' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/camera.svg)' }} />} />
                        </Dropdown>

                        {/* <Dropdown title='Egyéb fejlesztések' icon='FaSeedling' >
                            <DropdownItem title='Kerékpáros fejlesztések' >
                                <DropdownItem sub title='MOBI pontok' array='type' enum='MOBI' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/mobi.svg)' }} />} />
                                <DropdownItem sub title='BUBI pontok' array='type' enum='BUBI' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/bubi.svg)' }} />} />
                                <DropdownItem sub title='Kerékpártámaszok' array='type' enum='BIKE_SUPPORT' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/bike-support.svg)' }} />} />
                                <DropdownItem sub title='Kerékpáros nyomvonal fejlesztések' array='type' enum='BIKE_TRAIL' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/bike-trail.svg)' }} />} />
                            </DropdownItem>
                            <DropdownItem title='Tempo30 forgalomcsillapítás' >
                                <DropdownItem sub title='Tempo30 zónák' array='type' enum='TEMPO_30' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/tempo30.svg)' }} />} />
                                <DropdownItem sub title='Csomópont kiemelések' array='type' enum='NODE_HIGHLIGHT' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/node-highlight.svg)' }} />} />
                            </DropdownItem>
                            <DropdownItem title='Parkolást érintő fejlesztések' >
                            </DropdownItem>
                            <DropdownItem title='Homlokzatfelújítási program' array='type' enum='FACADE_RENOVATION' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/facade.svg)' }} />} />
                            <DropdownItem title='Térfigyelő kamerarendszer' array='type' enum='CAMERA' filter={props.filter} onChange={(d) => props.onChange(d)} rightChildren={<div className='w-4 h-4 bg-cover bg-center' style={{ backgroundImage: 'url(/assets/images/tv-2030-map/type/camera.svg)' }} />} />
                        </Dropdown> */}

                    </div>

                </div>

            </motion.div>
        </>
    );
}

function SearchBar(props) {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchOpen, setSearchOpen] = useState(false);
    const [result, setResult] = useState('');

    const minTermLenth = 3;

    // useEffect(() => {
    //     if (searchTerm.length >= minTermLenth)
    //         query(searchTerm);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [searchTerm]);

    useEffect(() => {
        if (!searchOpen || searchTerm.length < minTermLenth)
            setResult(null);
        else if (props.itemList)
            query(searchTerm);
    }, [searchTerm, searchOpen, props.itemList]);

    const query = (term) => {
        // const first = result ? result.items.length : 0;
        // console.log(result)
        QueryControl.query({ 'query': term, 'pageType': 'TV2030', 'firstResult': 0, 'maxResult': 20, 'directoryId': 31 }).then(result => { setResult(result) });
    }

    const getItem = (id) => {
        if (props.itemList)
            for (let i = 0; i < props.itemList.length; i++)
                if (props.itemList[i].content.id === id)
                    return (props.itemList[i])
        return null;
    }

    return (
        <div className='w-full relative z-10 flex flex-col'>

            {searchOpen && <div className='absolute w-full bg-tv-gray-lightest border rounded-b-md shadow-lg z-0 order-2 top-6 pt-4 pb-2 px-2 flex flex-col gap-2'>

                {searchTerm.length < minTermLenth && <div className='text-xs text-tv-gray'>A kereséshez adjon meg még {minTermLenth - searchTerm.length} karaktert.</div>}

                {(result && result.count > 0) && result.items.map((item, index) => <SearchResultItem key={index} data={getItem(item.contentId)} onItemKey={props.onItemKey} />)}

                {result && result.count === 0 && <div className='text-xs text-tv-gray'>A keresett kifejezés nem található.</div>}

            </div>}

            <div className='flex h-8 px-2 bg-tv-gray-lightest rounded-md ring-1 ring-tv-gray focus-within:ring-2 focus-within:ring-tv-red transition z-50 order-1' >

                <input className='flex-1 text-sm outline-none bg-transparent' placeholder='Keresés a fejlesztések között' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} onFocus={() => setSearchOpen(true)} onBlur={() => setSearchOpen(false)} />

                <button className='min-h-full flex items-center justify-center ml-2 text-tv-gray-darker hover:text-tv-red transition-colors'>
                    <DynamicIcon iconName='FaSearch' />
                </button>

            </div>

        </div>
    );
}

function SearchResultItem(props) {
    const _data = {
        title: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'title'),
        type: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'type'),
        readiness: (props.data && props.data.content) && getValueFromParameterMap(props.data.content, 'readiness'),
        coverImage: (props.data && props.data.content) && (getValueFromParameterMap(props.data.content, 'cover-image') && getValueFromParameterMap(props.data['content'], 'cover-image').downloadUrl),
    }

    return (<button className='rounded-md bg-white hover:bg-tv-red hover:text-white transition hover:ring-0 ring-1 ring-tv-gray-light px-2 py-1 text-xs text-left flex items-center justify-between' onMouseDown={() => props.onItemKey(props.data.content.key, props.data.content.id)}>
        <div>{_data.title}</div>
        <div className='text-xxs'>{getValueFromParameterMap(_data.type, 'title')}</div>
    </button>);
}

function MapButton(props) {
    return (
        <button className='w-14 h-14 flex items-center justify-center rounded-md ring-1 ring-tv-gray overflow-hidden hover:ring-2 hover:ring-tv-red transition group' onClick={props.onClick}>
            {props.children}
        </button>
    );
}

function Dropdown(props) {
    const [open, setOpen] = useState(false);

    const iconBoxClassName = 'w-7 h-7 bg-white text-tv-gray-darker rounded-md flex items-center justify-center transition';

    return (
        <div className='w-full flex flex-col gap-0 overflow-hidden'>

            <button className={'w-full flex gap-2 items-center rounded-md transition bg-white  ' + (open ? 'bg-opacity-100 ring-0 ring-tv-gray hover:bg-opacity-50' : 'bg-opacity-50 group hover:bg-opacity-100')} onClick={() => setOpen(!open)}>

                <div className={iconBoxClassName + ' group-hover:bg-tv-red-dark group-hover:text-white'}>
                    <DynamicIcon iconName={props.icon} />
                </div>

                <div className='flex-1 text-sm font-semibold text-left'>{props.title}</div>

                <div className={iconBoxClassName}>
                    <DynamicIcon className={'transition transform ' + (open ? 'rotate-180' : 'rotate-0')} iconName='FaChevronDown' />
                </div>

            </button>

            <motion.div
                className='border-ll border-tv-gray-dark ml-3.5'
                initial={'hidden'}
                animate={open ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: 1, height: 'auto', scaleY: 1 }, 'hidden': { opacity: 0, height: 0, scaleY: 0 } }}>

                <div className='flex flex-col'>{props.children}</div>

            </motion.div>

        </div>
    );
}

function DropdownItem(props) {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        if (props.filter && props.array && props.enum) {
            let data = {};
            data[props.array] = props.enum;
            props.onChange(data);
        }
    };

    const isActive = () => {
        if (props.filter && props.array && props.enum)
            return props.filter[props.array].indexOf(props.enum) > -1 ? true : false;
        return false;
    };

    return (
        <div className={'w-full flex flex-col gap-0 ' + (props.sub ? '' : 'first:mt-2')} onClick={() => handleClick()}>

            <button className={'relative w-full flex items-center text-left py-1 rounded-r-md sm:hover:bg-white hover:bg-opacity-50 transition my-px ' + (isActive() ? 'bg-white bg-opacity-75' : 'sm:hover:bg-white hover:bg-opacity-50')} onClick={() => setOpen(!open)}>

                <div className='absolute top-0 w-px bg-white h-full' />

                {(!props.sub && props.children) && <div className={'absolute bottom-0 h-px bg-white transition ' + (open ? 'opacity-100' : 'opacity-0')} style={{ width: '15px' }} />}
                <div className='w-2 h-2 bg-white -ml-1 rounded-full'>

                </div>

                <div className='ml-0.5 pl-4 text-xs flex-1'>{props.title}</div>

                {(props.rightChildren || props.children) && <div className='w-7 h-full flex items-center justify-center'>
                    {props.rightChildren && props.rightChildren}
                    {props.children && <DynamicIcon className={'text-xs transition transform ' + (open ? 'rotate-90' : 'rotate-0')} iconName='FaChevronRight' />}
                </div>}

            </button>

            {props.children && <motion.div
                className='border-ll border-tv-gray-dark ml-3.5'
                initial={'hidden'}
                animate={open ? 'visible' : 'hidden'}
                variants={{ 'visible': { opacity: 1, height: 'auto', scaleY: 1 }, 'hidden': { opacity: 0, height: 0, scaleY: 0 } }}>

                <div className='flex flex-col'>{props.children}</div>

            </motion.div>}

        </div>
    );
}